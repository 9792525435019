import React, { useEffect, useState, useRef } from "react";
import SeymourText from "../Components/SeymourText";
import { useEventResult } from "../State/EventResultContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import "./SoundCheck.css";
import StepIndicator from "../Components/StepIndicator";
import Navbar from "../Components/Navbar";
import { LiveAudioVisualizer } from "react-audio-visualize";
import {
  API_SOCKET_URL,
  DEEPGRAM_SOCKET_URL,
  DEEPGRAM_API_TOKEN,
  SUPPORTED_LANGUAGES,
} from "../utils/constants";
import { useErrorRenderer } from "../Hooks/useErrorRenderer";
import { useLoginResult } from "../State/LoginResultContext";
import { MdLanguage } from "react-icons/md";

function EventDetail(props) {
  const [mediaRecorder, setMediaRecorder] = useState();
  const { loginResults } = useLoginResult();
  const visualizerRef = useRef(null);
  const { eventResults } = useEventResult();
  const websocketRef = useRef(null);
  const deepGramSocketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const supportedLangCodes = Object.keys(SUPPORTED_LANGUAGES);
  const [status, setStatus] = useState("Not Connected");
  const [speechLanguage, setSpeechLanguage] = useState("en-US");
  const [isNova2Model, setIsNova2Model] = useState(false);
  const [isStreamingMode, setIsStreamingMode] = useState(false);
  const [, setMessage, , setIsError, resetErrorState] = useErrorRenderer();
  const navigate = useNavigate();
  const [currentTimeInSecond, setCurrentTimeInSecond] = useState(0);
  const [transcriptCount, setTranscriptCount] = useState(0);

  useEffect(() => {
    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        const audioBlob = new Blob([event.data], { type: "audio/wav" });
        visualizerRef.current.setBlob(audioBlob);
      };
      recorder.start();
      setMediaRecorder(recorder);
    };
    startRecording();
  }, []);

  useEffect(() => {
    if (eventResults.id) {
      websocketRef.current = new WebSocket(API_SOCKET_URL, [
        "event",
        eventResults.id,
      ]);
      websocketRef.current.onopen = () => {};
      websocketRef.current.onmessage = (message) => {
        const received = JSON.parse(message.data);
        if (received.type === "transcript") {
          if (document.getElementById("numConnected")) {
            document.getElementById(
              "numConnected"
            ).innerHTML = `Attendees Connected: ${received.numConnected || 0}`;
          }
        }
      };

      websocketRef.current.onclose = () => {
        setStatus("Not Connected");
      };

      websocketRef.current.onerror = (error) => {
        setMessage(error.message);
        setIsError(true);
        resetErrorState();
      };
    }
    // eslint-disable-next-line
  }, [eventResults?.id]);

  const handleEngineChange = () => {
    setIsNova2Model((prev) => (!prev));
  }

  const handleSetLang = (e) => {
    setSpeechLanguage(e.target.value);
  }

  const startRecording = () => {
    console.log("isNova2Model", isNova2Model)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        if (!MediaRecorder.isTypeSupported("audio/webm")) {
          setMessage("Browser not supported");
          setIsError(true);
          resetErrorState();
          return;
        }
        setIsStreamingMode(true);
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });
        console.log("speechLanguage",speechLanguage)
        let dpmUrl = `${DEEPGRAM_SOCKET_URL}?punctuate=true&diarize=true&language=${speechLanguage}`;
        if (eventResults.keywords && eventResults.keywords.length > 0) {
          const keywordArr = eventResults.keywords.split(" ");
          for (let i = 0; i < keywordArr.length; i++) {
            dpmUrl += `&keywords=${keywordArr[i]}`;
          }
        }
        const streamUrl = `${dpmUrl}${isNova2Model ? "&model=nova-2" : "&model=nova-2-general"}`;
        deepGramSocketRef.current = new WebSocket(streamUrl, [
          "token",
          DEEPGRAM_API_TOKEN,
        ]);

        setInterval(() => {
          setCurrentTimeInSecond((prev) => prev + 1);
        }, 1000);

        deepGramSocketRef.current.onopen = () => {
          setStatus("Connected");
          mediaRecorderRef.current.addEventListener(
            "dataavailable",
            async (event) => {
              if (
                event.data.size > 0 &&
                deepGramSocketRef.current.readyState === 1
              ) {
                deepGramSocketRef.current.send(event.data);
              }
            }
          );
          mediaRecorderRef.current.start(1000);
        };

        deepGramSocketRef.current.onmessage = (message) => {
          const received = JSON.parse(message.data);
          const transcript = received?.channel?.alternatives[0]?.transcript;
          console.log("transcript", transcript);
          const speaker = `Speaker${
            received?.channel?.alternatives[0]?.words[0]?.speaker + 1
          }`;
          if (transcript && received.is_final) {
            websocketRef.current.send(
              JSON.stringify({
                type: "transcript",
                lang: speechLanguage,
                transcript,
                eventId: eventResults.id,
                speaker,
                organizationId: loginResults.id,
                eventName: eventResults.name,
                eventLocation: eventResults.location,
                transcriptCount: transcriptCount + 1,
              })
            );
            setTranscriptCount((prev) => prev + 1);
          }
        };

        deepGramSocketRef.current.onclose = () => {};
        deepGramSocketRef.current.onerror = (error) => {
          setMessage(error.message);
          setIsError(true);
          resetErrorState();
        };
      })
      .catch((error) => {
        setMessage(error.message);
        setIsError(true);
        resetErrorState();
      });
  };

  const stopRecording = () => {
    websocketRef.current.send(
      JSON.stringify({ type: "endstream", eventId: eventResults.id })
    );
    mediaRecorderRef.current.stop();
    setIsStreamingMode(false);
    navigate("/my-events");
  };
 
  function formatTime(currentTimeInSecond) {
    const minutes = Math.floor(currentTimeInSecond / 60);
    const seconds = currentTimeInSecond % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  }
  
  return (
    <div>
      <Navbar />
      <div className="row">
        <StepIndicator currentStep={3} />
      </div>
      <div className="eventInfo1">
        <SeymourText props={"Check Live Audio"} />
      </div>

      <div className="mt-4 text-white text-center">
        <p>
          <span className="text-lg">{eventResults.name}</span>
        </p>
        <p>
          <span className="text-lg">{eventResults.location}</span>
        </p>
      </div>
      <div className="mt-4">
        <center>
          <button
            style={{ background: "#0d6fd8" }}
            className="linkCopy"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://seymour.events/` + eventResults.id
              );
              const popup = document.createElement("div");
              popup.innerText = "Link copied to clipboard!";
              popup.style.position = "fixed";
              popup.style.bottom = "10px";
              popup.style.left = "50%";
              popup.style.transform = "translateX(-50%)";
              popup.style.padding = "10px";
              popup.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
              popup.style.color = "#ffffff";
              popup.style.borderRadius = "5px";
              popup.style.zIndex = "9999";
              document.body.appendChild(popup);
              setTimeout(() => {
                document.body.removeChild(popup);
              }, 2000);
            }}
          >
            <span>
              <FontAwesomeIcon icon={faLink} style={{ color: "#ffffff" }} />
            </span>
            Copy link
          </button>
        </center>
      </div>
      {!isStreamingMode && (
        <>
          <div className="mt-4 col-span-1 flex justify-center">
            <h3 className="block text-lg font-medium text-white">Input Monitor</h3>
          </div>
          <div className="flex justify-center">
            {mediaRecorder && (
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder}
                width={200}
                height={75}
              />
            )}
          </div>
        </>
      )}


{/* <section className="flex items-center space-x-6 ">
         
         <label htmlFor="options" className="block text-sm font-medium text-gray">
         <MdLanguage
             color="white"
             size={32}
         /> {" "}
         </label>
       <select
         id="options"
         name="options"
         value={speechLanguage}
         onChange={handleSetLang}
         className="mt-1 p-3 block w-full bg-black rounded-md border border-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
       >
         {
           supportedLangCodes.map((option) => (
             <option value={option} key={option} className="text-gray">{SUPPORTED_LANGUAGES[option]}</option>
           ))
         }
       
       </select>

 </section> */}


  <div className="mt-4 col-span-1 flex justify-center">
      <div className="max-w-xs mx-auto flex flex-row items-center">
      <label htmlFor="options" className="block text-sm font-medium text-white">
      <MdLanguage
             color="white"
             size={32}
         /> {" "}
      </label>
      <select
        id="options"
        name="options"
        value={speechLanguage}
        onChange={handleSetLang}
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      >
        {
          supportedLangCodes.map((option) => (
            <option value={option}>{SUPPORTED_LANGUAGES[option]}</option>
          ))
        }
      
      </select>
    </div>
  </div>

    <div className="mt-4 col-span-1 flex justify-center gap-3">
          <h5 className="block text-sm font-medium text-white">Switch transcript model:</h5>

          <label className="checkbox-container" htmlFor="default" style={{color: 'white'}}>
                    <input 
                      type="checkbox" 
                      name="default"
                      id="default" 
                      checked={!isNova2Model}
                      onChange={handleEngineChange}
                    />
            <span className="checkmark"></span> nova
          </label>
          <br/>

          <label className="checkbox-container" htmlFor="nova-2" style={{color: 'white'}}>
                    <input 
                      type="checkbox" 
                      name="nova-2"
                      id="nova-2" 
                      checked={isNova2Model}
                      onChange={handleEngineChange}
                    />
            <span className="checkmark"></span> nova-2
          </label>
      </div>

      {isStreamingMode ? (
        <div className="mt-4">
          <div className="text-center">
            <p id="status">{status}</p>
            <p id="numConnected"></p>
            <p id="duration">Duration: {formatTime(currentTimeInSecond)}</p>
            <p id="transcript"></p>
          </div>
          <div className="mt-4 col-span-1 flex justify-center">
            <button
              className=" !bg-red-600 w-full sm:max-w-screen-md p-4 rounded-lg"
              id="stopButton"
              onClick={stopRecording}
            >
              <div>Stop Speech to Text</div>
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-4 col-span-1 flex justify-center">
          <button
            className=" !bg-green-600 w-full sm:max-w-screen-md p-4 rounded-lg"
            id="startButton"
            onClick={startRecording}
          >
            <div>Start Speech to Text</div>
          </button>
        </div>
      )}
    </div>
  );
}

export default EventDetail;
